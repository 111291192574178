import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Hero from '../components/Hero';
import Resource from '../components/Resource';

const FamilyResourcesPage = () => (
  <Layout title="Family Resources">
    <Hero
      mediaSource="FamilyResources-image-hero@2x.jpg"
      textOverlay="Family Resources"
    />
    <Section>
      <Container>
        <Row>
          <p>
            Take students inside global climate leader, Trane Technologies,
            where their employees are using sustainable solutions to create the
            systems you count on for comfortable, energy-efficient indoor air.
            Interactive educator and family activities bring lessons to life by
            having students engage with, discuss, and ultimately ideate
            solutions to real-world sustainability issues.
          </p>
        </Row>
      </Container>
    </Section>
    <Section>
      <Container>
        <Row>
          <Container>
            <Column size={12}>
              <div className="family-resources__row_divider">
                <h3>Goal Setting</h3>
              </div>
            </Column>
          </Container>
        </Row>
        <Row>
          <Container className="family-resources__container-display">
            <Column size={8}>
              <Resource
                img="familyresource-image-goalsetting.jpg"
                title="FAMILY ACTIVITY"
                audience="Grades 6-8"
                description="Students work with their families on a SMART (Specific, Measurable, Attainable, Relevant, Time-Bound) goal for creating a more sustainable future in their own neighborhood. Together they will identify a problem, create a solution, and work toward accomplishing their goal."
                titleClassOverride="family-resources__resource-title"
                actions={[
                  {
                    label: 'Download',
                    download:
                      '/pdfs/SustainableFutures-FamilyConnection-GoalSetting.pdf',
                  },
                ]}
              />
            </Column>
            <Column size={4}>
              <Resource
                img="familyresource-graphic-goalsetting@2x.png"
                title="EDUCATOR ACTIVITY"
                audience="Grades 6-8"
                duration="45 minutes"
                description="Work with students to understand local sustainability issues and the fundamentals of SMART goal setting. Then, facilitate a student discussion of SMART goals to solve a sustainability problem in their community."
                titleClassOverride="family-resources__resource-title"
                imageClassOverride="family-resources__image-container"
                actions={[
                  {
                    label: 'Download',
                    download:
                      '/pdfs/SustainableFutures-EducatorActivity-GoalSetting.pdf',
                  },
                ]}
              />
            </Column>
          </Container>
        </Row>
      </Container>
    </Section>
    <Section>
      <Container>
        <Row>
          <Container>
            <Column size={12}>
              <div className="family-resources__row_divider">
                <h3>Urban Heat Islands</h3>
              </div>
            </Column>
          </Container>
        </Row>
        <Row>
          <Container className="family-resources__container-display">
            <Column size={8}>
              <Resource
                img="familyresource-image-urbanheat@2x.jpg"
                title="FAMILY ACTIVITY"
                audience="Grades 6-8"
                description="Students will explain the urban heat island effect they learned about in class and brainstorm one action they can take, as a family, to help mitigate the problem. Together, students and their families will come to understand how their individual actions affect their local environment."
                titleClassOverride="family-resources__resource-title"
                actions={[
                  {
                    label: 'Download',
                    download:
                      '/pdfs/SustainableFutures-FamilyConnection-UrbanHeatIslands.pdf',
                  },
                ]}
              />
            </Column>
            <Column size={4}>
              <Resource
                img="familyresource-graphic-urbanheat@2x.png"
                title="EDUCATOR ACTIVITY"
                audience="Grades 6-8"
                duration="45 minutes"
                description="First, students learn what an urban heat island is and how to identify whether they are living in an area affected by one. Then they will engage in a discussion about what they can do to address the issue and cool down neighborhoods."
                titleClassOverride="family-resources__resource-title"
                imageClassOverride="family-resources__image-container"
                actions={[
                  {
                    label: 'Download',
                    download:
                      '/pdfs/SustainableFutures-EducatorActivity-UrbanHeatIslands.pdf',
                  },
                ]}
              />
            </Column>
          </Container>
        </Row>
      </Container>
    </Section>
    <Section>
      <Container>
        <Row>
          <Container>
            <Column size={12}>
              <div className="family-resources__row_divider">
                <h3>Sustainability in the News</h3>
              </div>
            </Column>
          </Container>
        </Row>
        <Row>
          <Container className="family-resources__container-display">
            <Column size={8}>
              <Resource
                img="familyresource-image-sustainabilitynews@2x.jpg"
                title="FAMILY ACTIVITY"
                audience="Grades 6-8"
                description="In this family activity, students and their families will examine their media literacy and learn to apply a more critical lens to the information they ingest. They will then apply what they’ve learned to identify reliable sources of information for researching a hypothetical purchase of a new family car."
                titleClassOverride="family-resources__resource-title"
                actions={[
                  {
                    label: 'Download',
                    download:
                      '/pdfs/SustainableFutures-FamilyConnection-SustainabilityInTheNews.pdf',
                  },
                ]}
              />
            </Column>
            <Column size={4}>
              <Resource
                img="familyresource-graphic-sustainabilitynews@2x.png"
                title="EDUCATOR ACTIVITY"
                audience="Grades 6-8"
                duration="45 minutes"
                description="Students will differentiate misinformation and untrustworthy news sites from reliable and credible information sources. With this new understanding, they will use the C.A.R.S. method to critically evaluate a sustainability issue of their choosing."
                titleClassOverride="family-resources__resource-title"
                imageClassOverride="family-resources__image-container"
                actions={[
                  {
                    label: 'Download',
                    download:
                      '/pdfs/SustainableFutures-EducatorActivity-SustainabilityInNews.pdf',
                  },
                ]}
              />
            </Column>
          </Container>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default FamilyResourcesPage;
